import { useState, useEffect, useContext } from 'react';
import { FetchedDataContext } from '../Contexts/FetchedDataContext';
import Popup from './Popup';
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


function Clock() {
    const { dataHour, dataDate, language } = useContext(FetchedDataContext);

    const [popUpTitle, setpopUpmapTitle] = useState("");
    const [popUpContents, setpopUpmapContents] = useState("");
    const [show, setShow] = useState(false);
    let { t, i18n } = useTranslation(['translation',language]);
    const FloatingInfoButton = styled(Button).attrs({ variant: "light" })`
        background: white;
        border: 1px solid rgba(0,0,0,0.25);
        padding: 0.5rem;
        border-radius: 30px; // round
        line-height: 1;
        z-index: 100; // not necessary to be this large but should ensure visibility

        position: fixed;
        right: 1rem;
        top: 1rem;
    `;
    const StyledTitle = styled.span`
        font-size: clamp(2rem, 3vw, 3rem);
        color: #0f172a;
        font-weight: 500;
        letter-spacing: 1px;
    `;

    return (
        <>
            <Popup status={show} setShow={setShow} mapTitle={popUpTitle} contents={popUpContents} />

            <FloatingInfoButton onClick={setPopUp}><svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="infoTitle infoDesc" role="img"><title id="infoTitle">Application info</title><desc id="infoDesc">Info about the application.</desc><path d="M11 0C4.92 0 0 4.92 0 11s4.92 11 11 11 11-4.92 11-11S17.08 0 11 0zm0 20.281A9.276 9.276 0 011.719 11 9.276 9.276 0 0111 1.719 9.276 9.276 0 0120.281 11 9.276 9.276 0 0111 20.281z" fill="#00417D" /><path d="M11 9.21a.86.86 0 00-.86.859v5.534a.86.86 0 101.72 0v-5.534a.86.86 0 00-.86-.86zM11 8.157a1.16 1.16 0 100-2.32 1.16 1.16 0 000 2.32z" fill="#00417D" /></svg></FloatingInfoButton>

            <div className="clock col-12">
                <StyledTitle>
                    {/* {date.toLocaleTimeString('en-US', {
                    hour12: false,
                    hour: "numeric",
                    minute: "numeric"
                })} */}
                    {dataHour + ":00"}
                </StyledTitle>
            </div>
        </>


    );

    function setPopUp() {
        setShow(true)
        setpopUpmapTitle((t("disclaimer")))
        setpopUpmapContents("<p>" + (t("disclaimer_text")) +
            "<p><a target=`_blank` rel=`noopener noreferrer` "
            + "href='https://developer.yr.no/doc/License/'>" +
            (t("weather_forcast_disclaimer")) + "</a>");

    }
}
export default Clock;
