import React, { useState } from "react";
import { useContext } from "react";
import styled from "styled-components";
import { FetchedDataContext } from "../Contexts/FetchedDataContext";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
const StyledWrapper = styled.div`
    
    flex-direction: column;
    background-color:rgb(242, 246, 252);
    padding:14px;
    border-radius:5px;
    
    > h3 {
        font-size: clamp(1rem, 1vw, 1.25rem);
        font-weight: 500;
        margin-bottom: 0;
        height:30px
    }
      >div  >table {
    table-layout: fixed;
    word-wrap: break-word;
    font-size:13px;
    overflow-y:scroll;
}
`;
const StyledTitle = styled.h2`
    font-size: 1rem;
    color: #475569;
    text-transform: uppercase;
    font-weight: 700;
`;

export default function EventInfo() {
    const { onGoingEvent, dataHour,setDataHour, dataDate, language, leftTimeStamp, apiError, map } = useContext(FetchedDataContext)
    const [showEvent, setShowEvent] = useState([])
    const [error, setError] = useState(false)
    let { t, i18n } = useTranslation(['translation', language]);

    useEffect(() => {
        if (apiError) {
            setError(true)
        }
    }, [apiError])
    useEffect(() => {
        if (onGoingEvent != null) {
            setShowEvent(onGoingEvent)
        }
    }, [onGoingEvent, dataDate])


    let noEvent = t("no_event")
    let month = new Date(leftTimeStamp).getMonth() + 1
    let date = new Date(leftTimeStamp).getDate() + "/" + (month < 10 ? "0" + month : month) + "/" + new Date(leftTimeStamp).getFullYear() + " "
    let time = new Date(leftTimeStamp).getHours() + ":" + (new Date(leftTimeStamp).getMinutes() < 10 ? "0" + new Date(leftTimeStamp).getMinutes() : new Date(leftTimeStamp).getMinutes()) + ":" + new Date(leftTimeStamp).getSeconds()

    return (
        <>
        <h3 style={{
            textTransform:'uppercase',
            fontSize: '1rem',
            color: 'rgb(71, 85, 105)',
            fontWeight:'bold',
            padding:'5px'
        }}>{t('todays_events')}</h3>
            <StyledWrapper>
                
                <div style={{
                    maxHeight: "280px",
                    overflowY: "auto"
                }}>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th className="bg-white sticky-top border-bottom">{t("name")}</th>
                                <th className="bg-white sticky-top border-bottom">{t("venue")}</th>
                                <th className="bg-white sticky-top border-bottom">{t("time")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {onGoingEvent.map((events, i) => {
                                if (events.length !== 0) {
                                    return events.map(event => (
                                        <tr key={crypto.randomUUID()} onClick={() => {
                                            setDataHour(i)
                                            map.flyTo({
                                                center: [event.lng, event.lat],
                                                zoom: 14,
                                                essential: true // this animation is considered essential with respect to prefers-reduced-motion
                                            });
                                        }
                                        }>
                                            <td>{event.event_name}</td>
                                            <td>{event.venue_name}</td>
                                            <td>{i + ":00"}</td>
                                            {/* Replace 'time' with the actual event time */}
                                        </tr>
                                    ));
                                }
                                return null;
                            })}
                        </tbody>
                    </Table>
                </div>

            </StyledWrapper>

            <p style={{ color: 'grey', fontSize: '12px', marginTop: '2%' }}>
                {t("updated_at") +" "+ (leftTimeStamp ? date + time : "")}
            </p>
        </>


    )

    function filterEvents(event) {
        if (!event.includes("VIP-PAKETTI") && !event.includes("null")) {
            return true
        }
        return false
    }
}