import React, { useContext, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FetchedDataContext } from "../Contexts/FetchedDataContext";
import fi from 'date-fns/locale/fi';
import en from 'date-fns/locale/en-US';

export default function DateSelector() {

    const { dataDate, setDataDate,language } = useContext(FetchedDataContext)
    
    let maxDate = getMaxDate(new Date())
    registerLocale("fi",fi)
    registerLocale("en",en)
    return (<DatePicker
        selected={new Date(dataDate)}
        onChange={(date) => setDataDate(date.toISOString())}
        dateFormat="d.M.yyyy"
        maxDate={maxDate}
        minDate={new Date()}
        calendarStartDay={1}
        disabledKeyboardNavigation
        locale={language=="en"?en:fi}
        onFocus={e => e.target.blur()}
    />
    );

    function getMaxDate(date){
        if (!date) {
            return date
        }
        const startTime = new Date(date).getTime()
        const month = date.getMonth();
        const year = date.getFullYear();
        const daysInMonth = new Date(year, month, 0).getDate();
        return new Date(startTime + daysInMonth * 24 * 60 * 60 * 1000)
    }

    

    
};