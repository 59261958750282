import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { FetchedDataContext } from "../Contexts/FetchedDataContext";
import { useTranslation } from "react-i18next";

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
        width: 48px;
        height: 48px;
    }
    > span {
        font-size: clamp(1.25rem, 3vw, 1.75rem);
        font-weight: 500;
        letter-spacing: 2px;
        margin-top: 0.5rem;
    }
    > h2 {
        font-size: 1rem !important;
        margin-bottom: 0;
    }
`;

export default function Weather() {
    const { dataHour, dataDate, getOpenIDToken, language, setapiError } = useContext(FetchedDataContext)
    const [temp, setTemp] = useState("N/A");
    const [weatherData, setWeatherData] = useState([]);
    let { t, i18n } = useTranslation(['translation',language]);

    useEffect(() => {
        let attributes = process.env.REACT_APP_WEATHER;
        (async () => {
            let token = await getOpenIDToken()
            // for (let i = 0; i <= 23; i++) {
            let startDateTime = dataDate.split('T')[0] + " 00:00:00"
            let startEpoch = Date.parse(startDateTime)
            let endDateTime = dataDate.split('T')[0] + " 23:59:59"
            let endEpoch = Date.parse(endDateTime)
            await getTimeSeriesData(startEpoch, endEpoch, token, attributes)

        })();
    }, [dataDate]);

    useEffect(() => {
        //console.log(weatherData.length)
        if (weatherData['bee12a16-a691-40d6-9103-408f88e39b09']) {
            setTemp(weatherData['bee12a16-a691-40d6-9103-408f88e39b09'][dataHour] ? weatherData['bee12a16-a691-40d6-9103-408f88e39b09'][dataHour].v : "N/A")
        }

    }, [dataHour, dataDate])

    async function getTimeSeriesData(startEpoch, endEpoch, token, attributes) {
        try {
            let timeSeriesData = await fetch('https://iot.tampere.fi/api/v1/data/timeseries?' +
                "startTime=" + startEpoch + "&endTime=" + endEpoch + "&chronologicalOrder=true&limit=100&" + attributes,
                {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    },
                });
            let jsonData = await timeSeriesData.json();
            if (jsonData) {
                setWeatherData(jsonData)
            }
            setTemp(jsonData['bee12a16-a691-40d6-9103-408f88e39b09'][12] ? jsonData['bee12a16-a691-40d6-9103-408f88e39b09'][12].v : "N/A")
        } catch (e) {
            console.log(e)
            setapiError(true)
        }

        //console.log(weatherData)

    }
    return (
        <StyledWrapper>
            <svg role="none" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 682.667 682.667"><defs><clipPath id="prefix__a" clipPathUnits="userSpaceOnUse"><path d="M0 512h512V0H0z" fill="#00417d" data-original="#000000" /></clipPath></defs><g clipPath="url(#prefix__a)" transform="matrix(1.33333 0 0 -1.33333 0 682.667)"><path d="M226 75.011c-25.327 0-45.931 20.608-45.931 45.94 0 25.331 20.604 45.94 45.931 45.94 25.327 0 45.931-20.609 45.931-45.94 0-25.332-20.604-45.94-45.931-45.94zM226 166.892v210.89" fill="none" stroke="#00417d" strokeWidth="30" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" data-original="#000000" /><path d="M285.443 208.659v228.887C285.443 470.382 258.829 497 226 497c-32.829 0-59.443-26.618-59.443-59.454V208.659c-28.055-19.059-46.488-51.229-46.488-87.707C120.069 62.436 167.496 15 226 15c58.505 0 105.931 47.436 105.931 105.952 0 36.478-18.432 68.648-46.488 87.707zM345.443 257.512h46.488M345.443 317.523h46.488M345.443 377.535h46.488M345.443 437.546h46.488" fill="none" stroke="#00417d" strokeWidth="30" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" data-original="#000000" /></g></svg>
            <span>{temp == "N/A" ? "N/A" : temp + "°C"}</span>
            <h2>{t("temperature")}</h2>

        </StyledWrapper>
    )
}