import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import BarChart from "./BarChart";
import styled from "styled-components";
import { FetchedDataContext } from "../Contexts/FetchedDataContext";
import { useTranslation } from "react-i18next";

const StyledList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 1.25rem;
    justify-content: center;
`;
const StyledListItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const ColorCircle = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 30px;
`;

export default function Popup(props) {
    const [showModal, setShow] = useState(false);
    const { language } = useContext(FetchedDataContext)
    let { t, i18n } = useTranslation(['translation',language]);

    if (props.status && !showModal) {
        setShow(true)
    }
    const handleClose = () => {
        setShow(false);
        props.setShow(false)
    };
    let content = props.contents.includes('VIP-PAKETIT') ? 'No Event' : (props.contents == "No Event Found" ? (language == "en" ? props.contents : "Ei tapahtumaa") : props.contents);

    // if(language!="en"){

    //     content = content.replace('Category: ','Kategoria: ')
    //     content = content.replace("Event about to start","Tapahtuma alkamassa")
    // }


    return (
        <>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.mapTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.mapTitle != "Disclaimer" && props.mapTitle != "Vastuuvapauslauseke" && props.mapTitle != "Events" && props.mapTitle != "Tapahtumat") ?
                        <>
                            <BarChart />
                            <StyledList className="mt-3">
                                <StyledListItem>
                                    <ColorCircle style={{ background: "#abc872" }}></ColorCircle>
                                    <span>0 - 100</span>
                                </StyledListItem>
                                <StyledListItem>
                                    <ColorCircle style={{ background: "#418155" }}></ColorCircle>
                                    <span>100 - 250</span>
                                </StyledListItem>
                                <StyledListItem>
                                    <ColorCircle style={{ background: "#f8de79" }}></ColorCircle>
                                    <span>250 - 500</span>
                                </StyledListItem>
                                <StyledListItem>
                                    <ColorCircle style={{ background: "#e8b455" }}></ColorCircle>
                                    <span>500 - 1000</span>
                                </StyledListItem>
                                <StyledListItem>
                                    <ColorCircle style={{ background: "#eb5e58" }}></ColorCircle>
                                    <span>1000 - 1500</span>
                                </StyledListItem>
                                <StyledListItem>
                                    <ColorCircle style={{ background: "#ae1e20" }}></ColorCircle>
                                    <span>>1500</span>
                                </StyledListItem>
                            </StyledList>
                        </>
                        : <div dangerouslySetInnerHTML={{ __html: (content) }} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} title={t("Close")}>
                        {t("Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
