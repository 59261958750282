import React, { useContext, useEffect, useState } from "react";

import { FetchedDataContext } from "../Contexts/FetchedDataContext";
import styled from "styled-components";

const StyledRangeSlider = styled.input`
    &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -.5rem;
    height: 24px;
    width: 24px;
}
`;
export default function TimeRangeSelector() {
    const { setDataHour, dataHour } = useContext(FetchedDataContext)
    return (
        <div>
            <StyledRangeSlider type="range" className="form-range" min="0" max="23" aria-label="Time selector" value={dataHour} onChange={(time) => setDataHour(time.target.value)} />
            <div className="d-flex justify-content-between" style={{ marginTop: "-5px" }}>
                <span style={{ letterSpacing: "1px" }} className="">00:00</span>
                <span style={{ letterSpacing: "1px" }} className="">23:00</span>
            </div>
        </div>

    );
};