import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MapBox from './Components/MapBox';
import StatusPanel from './Components/StatusPanel';
import Clock from './Components/Clock';
import Header from './Components/Header';
import DateTimeSelector from './Components/DateTimeSelector';
import { FetchedDataContext } from './Contexts/FetchedDataContext';
import { useEffect, useState } from 'react';
import styled from "styled-components";
import MediaQuery from 'react-responsive'
import { Toast } from 'react-bootstrap';
import i18n from './i18n';

const MainLayoutWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    touch-action: pan-y; // Prevent 'page bounce' effect on horizontal scroll

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
`;

const MainContentWrapper = styled.div`
    width: 100%;
    background: white;
    padding: var(--responsive-spacing-1-5-to-3);

    @media screen and (min-width: 769px) {
      width: clamp(320px, 33vw, 500px);
      min-width: 320px;
    }
`;


function App() {
  const [dataByDateTime, setDataByDateTime] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [dataHour, setDataHour] = useState(12);
  const [dataDate, setDataDate] = useState(new Date().toISOString());
  const [clickedLineId, setClickedLineId] = useState("");
  const [timeStamp, setTimeStamp] = useState("");
  const [leftTimeStamp, setleftTimeStamp] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [onGoingEvent, setOngoingEvent] = useState([]);
  const [language, setLanguage] = useState("fi");
  const [apiError, setApiError] = useState(false);
  const [map, setMap] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])
  return (
    <MainLayoutWrapper>
      <FetchedDataContext.Provider value={{
        dataByDateTime, setDataByDateTime, dataHour, setDataHour, dataDate, setDataDate,
        getOpenIDToken, clickedLineId, setClickedLineId, isLoading, setIsLoading, setEventData, leftTimeStamp, setleftTimeStamp,
        eventData, onGoingEvent, setOngoingEvent, language, setLanguage, 
        timeStamp, setTimeStamp, apiError, setApiError,map, setMap
      }}>
        <MainContentWrapper>
          <Header />
          <MediaQuery minWidth={769}>
            <Clock />
            <DateTimeSelector />
            <StatusPanel />
            <Toast
              //onClose={() => setapiError(false)}
              className='position-fixed bottom-0 start-50 p-3 bg-danger'
              style={{ zIndex: 1, transform: 'translate(-50%, 0px)', width: '30%' }}
              autohide
              show={apiError}
              delay={2000}
            >

              <Toast.Body className='text-white text-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                </svg>
                <span className='p-1'>
                  {language == "fi" ? "Tapahtui virhe haettaessa dataa. Yritä uudelleen myöhemmin" : "An error occured while fetching data. Please try again later."}

                </span>

              </Toast.Body>
            </Toast>
          </MediaQuery>
        </MainContentWrapper>
        <MapBox />
        <MediaQuery maxWidth={769}>
          <MainContentWrapper>
            <Clock />
            <DateTimeSelector />
            <StatusPanel />
            <Toast
              //onClose={() => setapiError(false)}
              className='position-fixed bottom-0 start-50 p-3 bg-danger'
              style={{ zIndex: 1, transform: 'translate(-50%, 0px)' }}
              autohide
              show={apiError}
              delay={2000}
            >

              <Toast.Body className='text-white text-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                </svg>
                <span className='p-1 text-center'>
                  {language == "fi" ? "Tapahtui virhe haettaessa dataa. Yritä uudelleen myöhemmin" : "An error occured while fetching data. Please try again later."}

                </span>

              </Toast.Body>
            </Toast>
          </MainContentWrapper>
        </MediaQuery>

      </FetchedDataContext.Provider>
    </MainLayoutWrapper>
  );

  async function getOpenIDToken() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("client_id", "iot-ticket-api-gateway");
    urlencoded.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    urlencoded.append("username", process.env.REACT_APP_USERNAME);
    urlencoded.append("password", process.env.REACT_APP_PASSWORD);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    try {
      const response = await fetch(process.env.REACT_APP_OPENID_AUTH_URL, requestOptions);
      const jsonResponse = await response.json();
      if (jsonResponse.status) {
        setApiError(true)
      } else {
        setApiError(false)
      }
      return jsonResponse.access_token;
    }
    catch (e) {
      setApiError(true)
      console.log(e)
    }

  }
}



export default App;
