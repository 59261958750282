import React, { useContext } from 'react';
import styled from "styled-components";
import { FetchedDataContext } from '../Contexts/FetchedDataContext';
import { ButtonGroup, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const StyledTitle = styled.h1`
    font-size: 1rem;
    color: #475569;
    text-transform: uppercase;
    font-weight: 700;
`;

export default function Header() {
    const { language, setLanguage } = useContext(FetchedDataContext)
    let { t, i18n } = useTranslation(['translation',language]);
    return (<div className="d-flex flex-column gap-3">
        <img src="assets/tampere-finland-logo.svg" alt="Tampere Finland logo" style={{ width: "200px" }} />
        <ButtonGroup aria-label="Select language" className="my-3">
            <Button variant={language == "fi" ? "secondary" : "light"} onClick={() => setLanguage("fi")}>Suomi</Button>
            <Button variant={language == "en" ? "secondary" : "light"} onClick={() => setLanguage("en")}>English</Button>
        </ButtonGroup>
        <StyledTitle>{t("city_visitor_count")}</StyledTitle>

    </div>
    )
}

