import React, { useRef, useEffect, useState, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import Popup from './Popup';
import { FetchedDataContext } from "../Contexts/FetchedDataContext";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const MapContainer = styled.div`
    background-color: #e2e8f0;
    width: 100%;
    height: 100%;
    min-height: 56vh; // Magic number where the controls are just about visible at once (below the map) on mobile
`;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN;
/**
 * 
 * @returns mapContainer for mapbox
 */
export default function MapBox() {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const lng = useRef(23.762411669434982);
    const lat = useRef(61.49829682666379);
    const [showModal, setShow] = useState(false);
    const [popUpmapTitle, setpopUpmapTitle] = useState("");
    const [popUpmapContents, setpopUpmapContents] = useState("");
    const [attributes, setAttributes] = useState("");
    const [timeStampData, setTimeStampData] = useState([]);
    const bounds = [
        [23.730575, 61.491592], // Southwest coordinates
        [23.803978, 61.502645] // Northeast coordinates
    ];
    const { dataDate, getOpenIDToken, dataHour, setDataByDateTime, setClickedLineId, setTimeStamp, timeStamp, setleftTimeStamp,
        leftTimeStamp, setIsLoading, setEventData, language, setOngoingEvent,
        onGoingEvent, apiError, setApiError, setMap } = useContext(FetchedDataContext)
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    let dateWiseData = []
    let eventDataObj = {}
    let timeStamps = []
    let hourlyEventDataList = new Array(24).fill([]);
    let attributeNameIDmapping = []
    let eventNames;
    let { t, i18n } = useTranslation(['translation', language]);
    //for setting screen size for mapbox zoom level
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);
    //on date change fetching updated data
    useEffect(() => {
        const fetchDataAndSetLocalStorage = async () => {
            await fetchingData();
            localStorage.setItem("currHour", dataHour);
        };

        fetchDataAndSetLocalStorage();


    }, [dataDate, attributes]);



    //on first render filter map data
    // useEffect(() => {
    //     setTimeout(() => {
    //         filterMapData(map)
    //     }, 1000)
    // }, [map.current])

    //in case of date or time change, filter map data to reset line colors
    useEffect(() => {
        if (map.current) {
            filterMapData(map)
            filterMapEventData(map.current)
        }
        localStorage.setItem("currHour", dataHour);
    }, [dataDate, dataHour])

    return (
        <>
            <Popup status={showModal} setShow={setShow} mapTitle={popUpmapTitle} contents={popUpmapContents} />
            <MapContainer ref={mapContainer} />
        </>
    );

    /**
     * 
     * @param {mapboxgl} map 
     * @returns null
     */
    function initMapbox(map) {

        if (map.current) {

            setTimeout(() => {
                setupCameraPoints(map.current)
                addEventPins(map.current);
                filterMapData(map)
            }, 500)
            return
        }
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/light-v11',
            center: [lng.current, lat.current],
            zoom: matches ? 14.8 : 13,
            maxZoom: 18,
            minZoom: 10,
            // maxBounds: bounds
        });

        map.current.on('load', () => {
            setupCameraPoints(map.current)
            addEventPins(map.current);
            filterMapData(map)
            map.current.resize(); // resize to fit the container
            setMap(map.current)
        });
    }

    /**
     * Fetching all the attribute data
     * first time series call fetches pedestrian data and second one for event data
     */
    async function fetchingData() {
        setIsLoading(true)
        let timeStampAttr = '&attributeIds=50d3e4a6-9352-465f-8ff9-4d5b61995f7c'
        let token = await getOpenIDToken()
        let startDateTime = dataDate.split('T')[0] + " 00:00:00"
        let startEpoch = Date.parse(startDateTime)
        let endDateTime = dataDate.split('T')[0] + " 23:59:59"
        let endEpoch = Date.parse(endDateTime)
        await getTimeSeriesData(startEpoch, endEpoch, token, attributes, 'pedestrian_data')
        await getTimeSeriesData(startEpoch, endEpoch, token, timeStampAttr, 'timeStamp_data')
        let eventAttributes = await getAttributeIds(process.env.REACT_APP_PULSSI_EVENTS_ASSET_ID, token)
        await getTimeSeriesData(startEpoch, endEpoch, token, eventAttributes, 'event_data')

        //await getAttributeIds(token)
        formatEventData()
        initMapbox(map)
        // if (map.current.isStyleLoaded())
        //     addEventPins(map.current);
    }




    /**
     * 
     * @param {mapboxgl} map
     * sets filter based on hour slider 
     */
    function filterMapData(map) {
        if (map.current) {
            let filters = ['==', 'hour', parseInt(dataHour)];
            map.current.setFilter('route-line', filters);

        }
    }
    function filterMapEventData(map) {
        if (map) {
            let filters = ['in', parseInt(dataHour), ['get', 'ongoingHours']];
            const layers = map.getStyle().layers;
            layers.forEach(layer => {
                if (layer.id.startsWith('points-layer-')) {
                    map.setFilter(layer.id, filters);
                }
            });
        }
    }

    /**
     * 
     * @param {int} startEpoch 
     * @param {int} endEpoch 
     * @param {String} token 
     * @param {String} attributes 
     * @param {String} dataType
     * Fetch all the attribute data passed in attributes string based on start and end time
     * sets eventData and dataByDateTime 
     */

    async function fetchData(startEpoch, endEpoch, token, chunkedAttributeIds) {
        try {
            const timeSeriesData = await fetch("https://iot.tampere.fi/api/v1/data/timeseries?startTime=" + startEpoch +
                "&endTime=" + endEpoch + "&chronologicalOrder=true&limit=100&attributeIds=" + chunkedAttributeIds, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
            });
            return await timeSeriesData.json();
        } catch (e) {
            console.error(e);
            return { status: 'error' }; // Return an error status object
        }
    }

    async function fetchAndProcessData(chunks, startEpoch, endEpoch, token) {
        let jsonData = {}; // Change from array to object
        try {
            for (const chunk of chunks) {
                let chunkedAttributeIds = chunk.join('&attributeIds=');
                const parsedJson = await fetchData(startEpoch, endEpoch, token, chunkedAttributeIds);
                jsonData = { ...jsonData, ...parsedJson };

                parsedJson.status === 'error' ? setApiError(true) : setApiError(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);

        }

        return jsonData;
    }

    async function getTimeSeriesData(startEpoch, endEpoch, token, attributes, dataType) {
        let attributesArray = attributes.split("&attributeIds=").filter(attribute => attribute != "");
        const chunks = traverseAttributeArrayInChunks(attributesArray, 40);

        try {
            const jsonData = await fetchAndProcessData(chunks, startEpoch, endEpoch, token);
            setIsLoading(false);

            if (dataType === "event_data") {
                //eventDataList.push(jsonData);
                eventDataObj = jsonData
                setEventData(jsonData);
                //console.log(jsonData)
            } else if (dataType === "pedestrian_data") {
                dateWiseData.push(jsonData);
                setDataByDateTime(dateWiseData);
            } else {
                timeStamps.push(jsonData);
                setTimeStampData(timeStamps);
            }
        } catch (error) {
            console.error("Error:", error);
            setIsLoading(false);
        }
    }

    function traverseAttributeArrayInChunks(array, chunkSize) {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            result.push(chunk);
        }
        return result;
    }

    async function getAttributeIds(assetID, token) {
        var attributes = ""
        let response = await fetch("https://iot.tampere.fi/api/v1/assets/" + process.env.REACT_APP_ORG_ID + "/" + assetID + "/attributes", {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        let jsonParsedResponse = await response.json()
        jsonParsedResponse.forEach(response => {
            mapAttributeIds(response.name, response.id)
            attributes += "&attributeIds=" + response.id
        });
        return attributes

    }

    function mapAttributeIds(attributeName, ID) {
        attributeNameIDmapping[attributeName] = ID
    }


    /**
 * 
 * @param {mapboxgl.Map} map
 * sets up camera points from the predefined json file
 */
    function setupCameraPoints(map) {
        const camLocation = require("../assets/camera-locations.json")
        let camAttributes = ""
        var locations = []
        camLocation.forEach(location => {
            locations.push(location)
            location.footpath.map(camDirections => camAttributes += "&attributeIds=" + camDirections.id)
        });
        setAttributes(camAttributes)
        formGeoJsonFeatures(locations, map)

    }

    function addEventPins(map) {
        if (eventDataObj) {
            let eventsCounter = 0

            if (!map.hasImage("eventIcon")) {
                map.loadImage(
                    'assets/marker.png',
                    (error, image) => {
                        if (error) throw error;
                        map.addImage("eventIcon", image);
                    })
            }
            //removing existing layer
            const layers = map.getStyle().layers;
            if (layers) {
                layers.forEach(layer => {
                    if (layer.id.startsWith("points-layer")) {
                        map.removeLayer(layer.id);
                        if (map.getSource(layer.source)) {
                            map.removeSource(layer.source);
                        }
                    }
                });
            }//removing existing layer
            eventNames.forEach((events, i) => {
                if (events.length !== 0) {
                    events.forEach(event => {
                        const sourceId = `points-${eventsCounter}`;
                        const layerId = `points-layer-${eventsCounter}`;
                        eventsCounter++;

                        map.addSource(sourceId, {
                            'type': 'geojson',
                            'data': {
                                'type': 'FeatureCollection',
                                'features': [
                                    {
                                        'type': 'Feature',
                                        'geometry': {
                                            'type': 'Point',
                                            'coordinates': [event.lng, event.lat]
                                        },
                                        'properties': {
                                            'title': event.event_name,
                                            'desc': event.desc,
                                            'venue_name': event.venue_name,
                                            'address': event.address,
                                            "marker-size": "small",
                                            'hour': i,
                                            "ongoingHours": [i - 2, i - 1, i, i + 1, i + 2]
                                        }
                                    }
                                ]
                            }
                        });

                        map.addLayer({
                            'id': layerId,
                            'type': 'symbol',
                            'source': sourceId,
                            'layout': {
                                'icon-image': "eventIcon",
                                'text-field': ['get', 'title'],
                                'text-font': [
                                    'Open Sans Regular',
                                    'Arial Unicode MS Regular'
                                ],
                                'text-offset': [0, (String(['get', 'title']).length - 7)],
                                'text-anchor': 'top',
                                "icon-allow-overlap": true,
                                'text-size': 12
                            },
                            'paint': {
                                'text-color': '#000', // Text color
                                'text-halo-color': '#fff', // Halo color if needed
                                'text-halo-width': 2 // Halo width if needed
                            },
                            'filter': ['in', parseInt(dataHour), ['get', 'ongoingHours']],
                            'minzoom': 13, // Ensure the layer is visible from zoom level 0
                            'maxzoom': 24 // Ensure the layer is visible up to zoom level 24
                        });

                        map.on('mouseenter', layerId, () => {
                            map.getCanvas().style.cursor = 'pointer';
                        });

                        // Change it back to a pointer when it leaves.
                        map.on('mouseleave', layerId, () => {
                            map.getCanvas().style.cursor = '';
                        });

                        map.on('click', layerId, (e) => {
                            const properties = e.features[0].properties;
                            setShow(true)
                            setpopUpmapTitle(t("events"))
                            setpopUpmapContents(`<b>` + (t("event_name")) + `: </b> ${properties.title}<br>
                            <b>` + (properties.desc!="-"?t("description")+`: </b>`+properties.desc+"<br>":"") + `</b> 
                            <b>` + (t("address")) + `: </b> ${properties.address}<br><b>` + (t("hour")) + `: </b>${properties.hour}:00`);
                        });
                    });
                }
            });
        }
    }



    /**
     * 
     * @param {Object} location 
     * @returns an Array of [
            [Start Longitude,Latitude],
            [End Longitude,Latitude],
            [ID of that attribute],
            updated timestamp attribute id,
            direction of the attribute
        ]
     */
    function getLocationStartEnd(location) {
        let startEndPos = []
        if (location.footpath) {
            location.footpath.map(coordinates => startEndPos.push(
                [
                    [coordinates.start.lng, coordinates.start.lat],
                    [coordinates.end.lng, coordinates.end.lat],
                    coordinates.id,
                    location.timestamp,
                    coordinates.direction
                ]
            ))
        }
        return startEndPos
    }
    /**
     * 
     * @param {Array} locations [camera location objects]
     * @param {mapboxgl} map 
     * 
     * some clarifications//
     * dateWiseData has only 1 index which has all the data
     * startEnd[j][2] here we have the attribute id, so basically the formation is
     *  dateWiseData[0]['idOfAttribute']
     *  dateWiseData[0]['idOfAttribute'][i], i is the hour here
     *  therefore pushing hour specific data in the features array
     */
    function formGeoJsonFeatures(locations, map) {
        let features = []
        locations.forEach(location => {
            let startEnd = getLocationStartEnd(location)
            for (let i = 0; i <= 23; i++) {
                for (let j = 0; j < startEnd.length; j++) {
                    let finalTimeStamp = 0
                    //setting up the timestamp
                    if (timeStampData[0]) {
                        if (timeStampData[0][startEnd[j][3]]) {
                            let arrayLength = timeStampData[0][startEnd[j][3]].length
                            let timeStamp1 = timeStampData[0][startEnd[j][3]][arrayLength - 1]
                            let timeStamp2 = timeStampData[0][startEnd[j][3]][arrayLength - 2]

                            if (timeStamp1) {
                                let direction = timeStamp1.v.split(" ")
                                if (direction == startEnd[j][4]) {
                                    finalTimeStamp = timeStamp1.ts
                                } else {
                                    if (timeStamp2) {
                                        finalTimeStamp = timeStamp2.ts
                                    }

                                }
                            }
                        }
                    }
                    if (leftTimeStamp == "") {
                        if (finalTimeStamp != 0) {
                            setleftTimeStamp(finalTimeStamp)
                        }
                    }
                    // pedestrian count
                    let count = 0;
                    if (dateWiseData[0]) {
                        if (dateWiseData[0].status == undefined) {
                            if (dateWiseData[0][startEnd[j][2]]) {
                                count = dateWiseData[0][startEnd[j][2]][i] ? Math.round(dateWiseData[0][startEnd[j][2]][i].v) : 0
                            }

                        }
                    }


                    let feature = {
                        'type': 'Feature',
                        'properties': {
                            'description': location.name,
                            'id': startEnd[j][2],
                            'hour': i,
                            'total_count': count,
                            'timeStamp': finalTimeStamp
                        },
                        'geometry': {
                            'coordinates': [startEnd[j][0], startEnd[j][1]],
                            'type': 'LineString'
                        }
                    }
                    features.push(feature)
                }
            }
        });

        var geojson = {
            'type': 'FeatureCollection',
            'features': features
        };

        if (map.getLayer("route-line")) {
            map.removeLayer("route-line");
        }

        if (map.getSource("line")) {
            map.removeSource("line");
        }

        map.addSource('line', {
            type: 'geojson',
            data: geojson
        });

        map.addLayer({
            type: 'line',
            source: 'line',
            id: 'route-line',
            layout: {
                'line-cap': 'round'
            },
            paint: {
                'line-color': [
                    'step',
                    ['get', 'total_count'],
                    '#abc872',
                    100, '#418155',
                    250, '#f8de79',
                    500, '#e8b455',
                    1000, '#eb5e58',
                    1500, '#ae1e20'
                ], // change the color according to the pedestrian count
                'line-width': 11
            }
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'route-line', () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'route-line', () => {
            map.getCanvas().style.cursor = '';
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'points', () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'points', () => {
            map.getCanvas().style.cursor = '';
        });
        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mouseenter', 'points1', () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'points1', () => {
            map.getCanvas().style.cursor = '';
        });

        map.on('click', 'route-line', (e) => {
            setShow(true)
            setpopUpmapTitle(e.features[0].properties.description)
            setClickedLineId(e.features[0].properties.id)
            // setTimeStamp(e.features[0].properties.timestamp)
            setpopUpmapContents("Forcasted hourly data comes here");
        });

        map.on('click', 'points', (e) => {
            setShow(true)
            setpopUpmapTitle("Nokia Arena")
            setpopUpmapContents(hourlyEventDataList[localStorage.getItem("currHour")] != null ? hourlyEventDataList[localStorage.getItem("currHour")] : "No Event Found");
        });
        map.on('click', 'points1', (e) => {
            setShow(true)
            setpopUpmapTitle("Nokia Arena")
            setpopUpmapContents(hourlyEventDataList[localStorage.getItem("currHour")] != null ? hourlyEventDataList[localStorage.getItem("currHour")] : "No Event Found");
        });

        const pulseSequence = [
            5,
            4.5,
            4,
            3.5,
            3,
            2.5,
            2,
            2.5,
            3,
            3.5,
            4,
            4.5,
        ];

        let step = 0;

        function animateLine(timestamp) {

            const newStep = parseInt(
                (timestamp / 250) % pulseSequence.length
            );

            if (newStep !== step) {
                map.setPaintProperty(
                    'route-line',
                    'line-blur',
                    pulseSequence[step]
                );
                step = newStep;
            }

            // Request the next frame of the animation.
            requestAnimationFrame(animateLine);
        }
        animateLine(0);

    }

    /**
     * Formating event data in hourly manner so that when the slider moves, we just pick the array index to show the data
     * For example:
     * eventNames[5] has 05:00 hour event data
     */
    function formatEventData() {
        setpopUpmapContents("No event found")
        eventNames = new Array(24).fill([]);
        if (eventDataObj) {
            // console.log(eventDetailsArray[1][0])
            //console.log(eventDataObj[attributeNameIDmapping['is_valid']])
            if (eventDataObj[attributeNameIDmapping['is_valid']])
                for (let j = 0; j < eventDataObj[attributeNameIDmapping['is_valid']].length; j++) {
                    if (isValidEvent(j)) {
                        let eventTime = parseInt(new Date(eventDataObj[attributeNameIDmapping['source']][j].ts).getHours())
                        let org_name = eventDataObj[attributeNameIDmapping['organizer_name']][j].v
                        let lat = eventDataObj[attributeNameIDmapping['venue_latitude']][j].v
                        let lng = eventDataObj[attributeNameIDmapping['venue_longitude']][j].v
                        let venue_name = eventDataObj[attributeNameIDmapping['venue_name']][j].v
                        let address = eventDataObj[attributeNameIDmapping['venue_postal_address']][j].v
                        let desc = eventDataObj[attributeNameIDmapping['description']][j].v
                        let event_name = eventDataObj[attributeNameIDmapping['name']][j].v
                        let is_valid = eventDataObj[attributeNameIDmapping['is_valid']][j].v


                        eventNames[eventTime] = [...eventNames[eventTime], {
                            event_name,
                            desc,
                            venue_name: venue_name == "-" ? address : venue_name,
                            address,
                            lat,
                            lng,
                            org_name,
                            is_valid
                        }]
                    }

                }
            setOngoingEvent(eventNames)
        }

    }

    function isValidEvent(eventObjIndex) {
        let validity = eventDataObj[attributeNameIDmapping['is_valid']][eventObjIndex].v
            && eventDataObj[attributeNameIDmapping['venue_postal_address']][eventObjIndex].v != "-"

        return validity
    }
}



