import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { FetchedDataContext } from "../Contexts/FetchedDataContext";
import { useTranslation } from "react-i18next";

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
        width: 48px;
        height: 48px;
    }
    > span {
        font-size: clamp(1.25rem, 3vw, 1.75rem);
        font-weight: 500;
        letter-spacing: 2px;
        margin-top: 0.5rem;
    }
    > h2 {
        font-size: 1rem !important;
        margin-bottom: 0;
    }
`;

export default function NumberOfVisitors() {
    const { dataHour, dataByDateTime, isLoading, language } = useContext(FetchedDataContext)
    const [visitors, setVisitors] = useState([])
    let { t, i18n } = useTranslation(['translation',language]);

    useEffect(() => {
        if (!isLoading) {
            let count = 0
            let cam_attributes = []
            let hourlyCount = []
            const camLocation = require("../assets/camera-locations.json")
            camLocation.forEach(location => {
                location.footpath.map(camDirections => cam_attributes.push(camDirections.id))
            });
            if (dataByDateTime[0]) {
                if (dataByDateTime[0].status == undefined) {
                    for (let j = 0; j <= 23; j++) {
                        for (let i = 0; i < cam_attributes.length; i++) {
                            
                            if (dataByDateTime[0][cam_attributes[i]]) {
                                if (dataByDateTime[0][cam_attributes[i]][j]) {
                                    count += Math.round(dataByDateTime[0][cam_attributes[i]][j].v)
                                }
                            }


                        }
                        hourlyCount[j] = count;
                        count = 0;
                    }
                    setVisitors(hourlyCount)
                } else {
                    console.log("undefined")
                }
            } else {
                console.log("undefined")
            }
        }

    }, [dataByDateTime])

    return (
        <StyledWrapper>
            <svg viewBox="0 0 512 512" width="48" height="48" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2"><g fill="#00417d" fillRule="nonzero"><path d="M437 268.152h-50.118c-6.821 0-13.425.932-19.71 2.646-12.398-24.372-37.71-41.118-66.877-41.118h-88.59c-29.167 0-54.479 16.746-66.877 41.118a74.825 74.825 0 00-19.71-2.646H75c-41.355 0-75 33.645-75 75v80.118c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45v-80.118c0-41.355-33.645-75-75-75zm-300.295 36.53v133.589H45c-8.271 0-15-6.729-15-15v-80.118c0-24.813 20.187-45 45-45h50.118c4.072 0 8.015.553 11.769 1.572a75.214 75.214 0 00-.182 4.957zm208.59 133.589h-178.59v-133.59c0-24.813 20.187-45 45-45h88.59c24.813 0 45 20.187 45 45v133.59zm136.705-15c0 8.271-6.729 15-15 15h-91.705v-133.59a75.214 75.214 0 00-.182-4.957 44.9 44.9 0 0111.769-1.572H437c24.813 0 45 20.187 45 45v80.119z" /><path d="M100.06 126.504c-36.749 0-66.646 29.897-66.646 66.646-.001 36.749 29.897 66.646 66.646 66.646 36.748 0 66.646-29.897 66.646-66.646s-29.897-66.646-66.646-66.646zm-.001 103.292c-20.207 0-36.646-16.439-36.646-36.646s16.439-36.646 36.646-36.646 36.646 16.439 36.646 36.646-16.439 36.646-36.646 36.646zM256 43.729c-49.096 0-89.038 39.942-89.038 89.038s39.942 89.038 89.038 89.038 89.038-39.942 89.038-89.038c0-49.095-39.942-89.038-89.038-89.038zm0 148.076c-32.554 0-59.038-26.484-59.038-59.038 0-32.553 26.484-59.038 59.038-59.038 32.554 0 59.038 26.484 59.038 59.038 0 32.554-26.484 59.038-59.038 59.038zm155.94-65.301c-36.748 0-66.646 29.897-66.646 66.646.001 36.749 29.898 66.646 66.646 66.646 36.749 0 66.646-29.897 66.646-66.646s-29.897-66.646-66.646-66.646zm0 103.292c-20.206 0-36.646-16.439-36.646-36.646.001-20.207 16.44-36.646 36.646-36.646 20.207 0 36.646 16.439 36.646 36.646s-16.439 36.646-36.646 36.646z" /></g></svg>
            <span>{visitors[dataHour] ? visitors[dataHour] : "N/A"}</span>
            <h2>{t("visitors")}</h2>

        </StyledWrapper>
    )
}