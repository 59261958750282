import React from "react";
import NumberOfVisitors from "./NumberOfVisitors";
import Weather from "./Weather";


import styled from "styled-components";
import EventInfo from "./EventInfo";

const StyledWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: var(--responsive-spacing-1-5-to-0);
    background-color: rgb(242 246 252);
    border-radius: 10px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    
    @media screen and (min-width: 769px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`;

export default function StatusPanel() {
    return <>
        <StyledWrapper>
            <NumberOfVisitors></NumberOfVisitors>
            <Weather></Weather>
            {/* <EventTime></EventTime> */}
        </StyledWrapper>
        <EventInfo></EventInfo>
    </>
}