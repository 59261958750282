import { useContext } from 'react';
import DateSelector from "./DateSelector";
import TimeRangeSelector from "./TimeRangeSelector";
import { FetchedDataContext } from '../Contexts/FetchedDataContext';

export default function DateTimeSelector(props) {
    const { dataDate,language } = useContext(FetchedDataContext)
    var options = { weekday: 'long'};

    return (
        <>
            <TimeRangeSelector />
            <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                <div className="d-flex align-items-center gap-2">
                    <DateSelector map={props.map} />
                </div>
                <span>{new Date(dataDate).toLocaleDateString(language=="en"?"en-US":"fi-Fi", options)}</span>
            </div>
        </>
    )
}