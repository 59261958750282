import {
    Chart as ChartJs,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from 'chart.js'
import { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { FetchedDataContext } from '../Contexts/FetchedDataContext';
ChartJs.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
)
export default function BarChart() {
    const { dataByDateTime, clickedLineId } = useContext(FetchedDataContext)

    let labels = []
    let dataSet = []
    let colors = []
    if (dataByDateTime[0] && dataByDateTime[0].status === undefined) {
        for (let i = 0; i <= 23; i++) {
            labels.push(i.toString());
    
            if (dataByDateTime[0][clickedLineId] && dataByDateTime[0][clickedLineId][i]) {
                let count = Math.round(dataByDateTime[0][clickedLineId][i].v);
                dataSet.push(count);
    
                // Use a switch statement to determine colors based on 'count'
                switch (true) {
                    case count > 1500:
                        colors.push('#ae1e20');
                        break;
                    case count > 1000:
                        colors.push('#eb5e58');
                        break;
                    case count > 500:
                        colors.push('#e8b455');
                        break;
                    case count > 250:
                        colors.push('#f8de79');
                        break;
                    default:
                        colors.push('#abc872');
                        break;
                }
            } else {
                dataSet.push(0);
                colors.push('#abc872');
            }
        }
    }
    


    const data = {
        labels: labels,
        datasets: [
            {
                label: 'pedestrians',
                data: dataSet,
                backgroundColor: colors
            }
        ]
    }
    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        scales: {
            y: {
                display: false,
            },
            x: {
                grid: {
                    display: false
                }
            }
        },
    };
    return (<>
        <Bar data={data} options={options}></Bar>
    </>)
}